import React from "react";
import { invariant } from "ts-invariant";
import {
  getVariationLabel,
  useVariationLoginUrl,
} from "../../constants/strings";
import { useCrossModalContext } from "../../hooks/useCrossModalContext";

export function CrossModalLoginBanner() {
  const { crossModalVariation } = useCrossModalContext();

  invariant(crossModalVariation);

  const loginUrl = useVariationLoginUrl(crossModalVariation);

  return (
    <p className="rounded border p-6 text-center leading-normal">
      Already have a {getVariationLabel(crossModalVariation)} account?
      <span className="block lg:inline"> </span>
      <a
        className="hcp:text-hcp-purple-400 ai:text-ai-blue-400 pin:text-pin-blue-400"
        href={loginUrl}
        target="_blank"
      >
        Login here.
      </a>
    </p>
  );
}
