import React from "react";
import { invariant } from "ts-invariant";
import { GlobalNavVariation } from "../../constants/constants";
import { useCrossModalContext } from "../../hooks/useCrossModalContext";

export const getVariationTitle = (variation: GlobalNavVariation) => {
  switch (variation) {
    case GlobalNavVariation.ACTIVATED_INSIGHTS:
      return "Create Certified Exceptional Customer & Employee Experience.";
    case GlobalNavVariation.HCP_EXPERIENCE:
      return "Learn the good, the bad, and the ugly about your business. Then take action.";
    case GlobalNavVariation.HCP_REPUTATION:
      return "Take control of your online reputation with HCP Reputation Management.";
    case GlobalNavVariation.HCP_TRAINING:
      return "Build an effective and efficient training program to deliver better care.";
    case GlobalNavVariation.PINNACLE_RETAIN:
      return "Keep your people with an effective retention program.";
    case GlobalNavVariation.PINNACLE_QI:
      return "Become a better provider with in-depth customer satisfaction insights.";
    case GlobalNavVariation.PINNACLE_CAHPS:
      return "Meet regulatory compliance and unlock in-depth customer insights.";
    default:
      throw new Error("Invalid variation");
  }
};

export function CrossModalTitle() {
  const { crossModalVariation } = useCrossModalContext();

  invariant(crossModalVariation);

  return (
    <p className="text-center text-2xl lg:px-8 lg:text-3xl">
      {getVariationTitle(crossModalVariation)}
    </p>
  );
}
