import { clsx } from "clsx";
import React, { ReactNode } from "react";

const buttonClassName = clsx(
  "inline-block px-4 py-2 leading-normal text-white",
  "hcp:bg-hcp-gray-100 hover:hcp:bg-hcp-gray-100/80 hcp:border hcp:border-hcp-gray-500 hcp:text-hcp-purple-800 hcp:rounded-sm",
  "ai:bg-ai-yellow-500 hover:ai:bg-ai-yellow-500/80 ai:text-ai-blue-800 shadow",
  "pin:bg-pin-blue-400 hover:pin:bg-pin-400/80"
);

interface ButtonProps {
  children: ReactNode;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export function Button({ children, onClick }: ButtonProps) {
  return (
    <button className={buttonClassName} type="button" onClick={onClick}>
      {children}
    </button>
  );
}

interface AnchorButtonProps {
  children: ReactNode;
  href: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  target?: React.HTMLAttributeAnchorTarget;
}

export function AnchorButton({
  children,
  href,
  onClick,
  target,
}: AnchorButtonProps) {
  return (
    <a
      className={buttonClassName}
      href={href}
      onClick={onClick}
      target={target}
    >
      {children}
    </a>
  );
}
