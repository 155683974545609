import React from "react";
import {
  Modal,
  ModalClose,
  ModalOverlay,
  ModalPanel,
} from "../../components/common/Modal";
import { CrossModalDemo } from "../../components/CrossModal/Demo";
import { CrossModalFeatures } from "../../components/CrossModal/Features";
import { CrossModalLoginBanner } from "../../components/CrossModal/LoginBanner";
import { CrossModalLogo } from "../../components/CrossModal/Logo";
import { CrossModalSubtitle } from "../../components/CrossModal/Subtitle";
import { CrossModalTitle } from "../../components/CrossModal/Title";

interface CrossModalProps {
  open: boolean;
  onClose: () => void;
}

export function CrossModal({ open, onClose }: CrossModalProps) {
  return (
    <Modal open={open} onClose={onClose}>
      <ModalOverlay />
      <ModalPanel onClose={onClose}>
        <ModalClose onClose={onClose} />
        <div className="ai:text-ai-blue-800 hcp:text-hcp-purple-800 space-y-8 px-8 pb-14 pt-8 text-[15px] leading-relaxed text-gray-600 lg:px-14">
          <CrossModalLoginBanner />
          <CrossModalLogo />
          <div>
            <CrossModalSubtitle className="mb-3" />
            <CrossModalTitle />
          </div>
          <CrossModalDemo />
          <CrossModalFeatures />
        </div>
      </ModalPanel>
    </Modal>
  );
}
