import React, { ReactNode, useEffect, useRef } from "react";
import ClearXIcon from "../../assets/modal/clear-x-icon.svg";

interface ModalProps {
  children: ReactNode;
  open: boolean;
  onClose: () => void;
}

// NOTE: We can't use most modal libraries because they render into portals in <body> and we use shadow dom
export function Modal({ children, open, onClose }: ModalProps) {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (open) {
      modalRef.current?.focus();
    }
  }, [open]);

  if (!open) {
    return null;
  }

  const handleKeyPress: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key === "Escape") {
      onClose();
    }
  };

  return (
    <div
      ref={modalRef}
      aria-modal="true"
      aria-hidden={open ? "true" : "false"}
      role="dialog"
      tabIndex={-1}
      onKeyDown={handleKeyPress}
    >
      {children}
    </div>
  );
}

export function ModalOverlay() {
  return <div className="fixed inset-0 bg-black/50" aria-hidden="true" />;
}

interface ModalPanelProps {
  children: ReactNode;
  onClose: () => void;
}

export function ModalPanel({ children, onClose }: ModalPanelProps) {
  return (
    <div
      className="fixed inset-0 flex overflow-y-auto p-4"
      onClick={(e) => {
        if (
          e.currentTarget !== e.target &&
          e.target instanceof Node &&
          e.currentTarget.contains(e.target)
        ) {
          return;
        }
        onClose();
      }}
    >
      <div className="shadow-ai relative m-auto w-full max-w-3xl bg-white">
        {children}
      </div>
    </div>
  );
}

interface ModalCloseProps {
  onClose: () => void;
}

export function ModalClose({ onClose }: ModalCloseProps) {
  return (
    <button
      className="absolute right-0 top-0 p-4 text-gray-500"
      type="button"
      onClick={onClose}
    >
      <ClearXIcon />
    </button>
  );
}
