import { createContext, useContext } from "react";
import { GlobalNavSite, GlobalNavVariation } from "../constants/constants";
import {
  HCP_EXPERIENCE_APP_HOST,
  HCP_REPUTATION_APP_HOST,
  HCP_TRAINING_APP_HOST,
  ACTIVATED_INSIGHTS_APP_HOST,
  PINNACLE_RETAIN_APP_HOST,
  PINNACLE_QI_APP_HOST,
  PINNACLE_CAHPS_APP_HOST,
} from "../constants/urls";

export interface GlobalNavHosts {
  hcpExperienceAppHost: string;
  hcpReputationAppHost: string;
  hcpTrainingAppHost: string;
  activatedInsightsAppHost: string;
  pinnacleRetainAppHost: string;
  pinnacleQiAppHost: string;
  pinnacleCahpsAppHost: string;
}

export const DEFAULT_APP_HOSTS: GlobalNavHosts = {
  hcpExperienceAppHost: HCP_EXPERIENCE_APP_HOST,
  hcpReputationAppHost: HCP_REPUTATION_APP_HOST,
  hcpTrainingAppHost: HCP_TRAINING_APP_HOST,
  activatedInsightsAppHost: ACTIVATED_INSIGHTS_APP_HOST,
  pinnacleRetainAppHost: PINNACLE_RETAIN_APP_HOST,
  pinnacleQiAppHost: PINNACLE_QI_APP_HOST,
  pinnacleCahpsAppHost: PINNACLE_CAHPS_APP_HOST,
};

export interface GlobalNavContextState {
  site: GlobalNavSite;
  variation: GlobalNavVariation;
  hosts: GlobalNavHosts;
}

export const GlobalNavContext = createContext<GlobalNavContextState>({
  site: GlobalNavSite.HCP,
  variation: GlobalNavVariation.HCP_EXPERIENCE,
  hosts: DEFAULT_APP_HOSTS,
});

export function useGlobalNavContext() {
  return useContext(GlobalNavContext);
}
