import { clsx } from "clsx";
import React, { ReactNode, useCallback, useMemo, useState } from "react";
import "../css/global-nav.css";
import { CrossModal } from "../components/CrossModal";
import { GlobalNavLogo } from "../components/Logo";
import { GlobalNavProfileDropdown } from "../components/Profile";
import { GlobalNavTabs } from "../components/Tabs";
import {
  GlobalNavSite,
  GlobalNavVariation,
  variationToSiteMap,
} from "../constants/constants";
import { CrossModalContext } from "../hooks/useCrossModalContext";
import {
  DEFAULT_APP_HOSTS,
  GlobalNavContext,
  GlobalNavContextState,
  GlobalNavHosts,
} from "../hooks/useGlobalNavContext";
import {
  GlobalNavPermissions,
  PermissionsContext,
} from "../hooks/usePermissionsContext";

// the values coming from the web component would be strings, but handle boolean too just in case
const parseBooleanAttribute = (attribute?: string | boolean) =>
  attribute === "true" || attribute === true;

export interface GlobalNavProps extends Partial<GlobalNavPermissions> {
  companyName: string;
  className?: string;
  editProfileUrl?: string;
  fileUploadUrl?: string;
  hideProfileDropdown?: boolean;
  hosts?: Partial<GlobalNavHosts>;
  profileDropdownChildren?: ReactNode;
  userName: string;
  variation: GlobalNavVariation;
  logoutUrl?: string;
}

export function GlobalNav({
  canAccessActivatedInsights = false,
  canAccessHcpExperience = false,
  canAccessHcpReputation = false,
  canAccessHcpTraining = false,
  canAccessPinnacleCAHPS = false,
  canAccessPinnacleMain = false,
  canAccessPinnacleRetain = false,
  className,
  companyName,
  editProfileUrl,
  fileUploadUrl,
  hideProfileDropdown = false,
  hosts,
  profileDropdownChildren,
  userName,
  variation,
  logoutUrl,
}: GlobalNavProps) {
  const site = variationToSiteMap[variation];
  const globalNavContext = useMemo<GlobalNavContextState>(
    () => ({
      site,
      variation,
      hosts: {
        ...DEFAULT_APP_HOSTS,
        ...hosts,
      },
    }),
    [site, variation]
  );

  const permissionsContext = useMemo(
    () => ({
      permissions: {
        canAccessActivatedInsights: parseBooleanAttribute(
          canAccessActivatedInsights
        ),
        canAccessHcpExperience: parseBooleanAttribute(canAccessHcpExperience),
        canAccessHcpReputation: parseBooleanAttribute(canAccessHcpReputation),
        canAccessHcpTraining: parseBooleanAttribute(canAccessHcpTraining),
        canAccessPinnacleCAHPS: parseBooleanAttribute(canAccessPinnacleCAHPS),
        canAccessPinnacleMain: parseBooleanAttribute(canAccessPinnacleMain),
        canAccessPinnacleRetain: parseBooleanAttribute(canAccessPinnacleRetain),
      },
    }),
    [
      canAccessActivatedInsights,
      canAccessHcpExperience,
      canAccessHcpReputation,
      canAccessHcpTraining,
      canAccessPinnacleCAHPS,
      canAccessPinnacleMain,
      canAccessPinnacleRetain,
    ]
  );

  const [isCrossModalOpen, setIsCrossModalOpen] = useState(false);
  const [crossModalVariation, setCrossModalVariation] =
    useState<GlobalNavVariation | null>(null);

  const crossModalContext = useMemo(() => {
    return {
      isCrossModalOpen,
      setIsCrossModalOpen,
      crossModalVariation,
      setCrossModalVariation,
    };
  }, [
    isCrossModalOpen,
    setIsCrossModalOpen,
    crossModalVariation,
    setCrossModalVariation,
  ]);

  const onCloseCrossModal = useCallback(() => {
    setIsCrossModalOpen(false);
  }, [setIsCrossModalOpen]);

  return (
    <GlobalNavContext.Provider value={globalNavContext}>
      <PermissionsContext.Provider value={permissionsContext}>
        <CrossModalContext.Provider value={crossModalContext}>
          <div
            className={clsx(
              {
                "font-hcp global-nav-hcp": site === GlobalNavSite.HCP,
                "font-ai global-nav-ai": site === GlobalNavSite.AI,
                "font-pin global-nav-pin": site === GlobalNavSite.PIN,
              },
              className
            )}
          >
            <div
              className={clsx(
                "flex h-[70px] items-center justify-between px-4 text-left text-base text-white",
                "hcp:bg-hcp-purple-800 ai:bg-ai-blue-800 pin:bg-pin-blue-800"
              )}
              id="hcp-global-nav"
            >
              <GlobalNavLogo />

              <GlobalNavTabs />

              <GlobalNavProfileDropdown
                companyName={companyName}
                userName={userName}
                editProfileUrl={editProfileUrl}
                fileUploadUrl={fileUploadUrl}
                hideProfileDropdown={hideProfileDropdown}
                profileDropdownChildren={profileDropdownChildren}
                logoutUrl={logoutUrl}
              />
            </div>
            <CrossModal open={isCrossModalOpen} onClose={onCloseCrossModal} />
          </div>
        </CrossModalContext.Provider>
      </PermissionsContext.Provider>
    </GlobalNavContext.Provider>
  );
}
