import { Menu, Transition } from "@headlessui/react";
import { clsx } from "clsx";
import React, { ReactNode, cloneElement, forwardRef } from "react";
import { GlobalNavVariation } from "../../constants/constants";
import { GlobalNavHosts } from "../../hooks/useGlobalNavContext";
import { GlobalNavPermissions } from "../../hooks/usePermissionsContext";

export interface GlobalNavDropdownItem {
  href: string;
  icon: React.ReactElement<{
    className?: string;
  }>;
  title: string;
  hostKey: keyof GlobalNavHosts;
  permissionsKey: keyof GlobalNavPermissions;
  modalVariation: GlobalNavVariation;
}

interface GlobalNavDropdownProps {
  children: ReactNode;
  className?: string;
}

export function GlobalNavDropdown({
  children,
  className,
}: GlobalNavDropdownProps) {
  return <div className={clsx("relative h-full", className)}>{children}</div>;
}

interface GlobalNavDropdownMenuItemsProps {
  children: ReactNode;
  className?: string;
}

export function GlobalNavDropdownMenuItems({
  children,
  className,
}: GlobalNavDropdownMenuItemsProps) {
  return (
    <Transition
      className={clsx("absolute mt-4", className)}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Menu.Items className="hcp:text-hcp-gray-500 ai:text-ai-blue-800 pin:text-pin-blue-800 divide-y overflow-hidden rounded bg-white shadow-xl">
        {children}
      </Menu.Items>
    </Transition>
  );
}

export interface GlobalNavDropdownItemProps {
  active?: boolean;
  as?: "a" | "button" | "div";
  children: ReactNode;
  className?: string;
  href?: string;
  iconStart?: React.ReactElement<{
    className?: string;
  }>;
  iconEnd?: React.ReactElement<{
    className?: string;
  }>;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

// base
const itemClassName = `hcp:font-semibold`;
// hover
const itemHoverClassName = `hover:hcp:text-hcp-green-500 hover:all:font-bold`;
// keyboard focus
const itemFocusClassName = `hcp:text-hcp-green-500 all:font-bold`;
// active (mouse down)
const itemActiveClassName = `active:hcp:bg-hcp-green-100 active:hcp:text-hcp-green-500 active:ai:bg-gray-100 active:all:font-bold`;

export const GlobalNavDropdownItem = forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  GlobalNavDropdownItemProps
>(
  ({
    active,
    as: Component = "a",
    children,
    className,
    href,
    iconStart,
    iconEnd,
    onClick,
  }) => {
    return (
      <Component
        className={clsx(
          "flex w-full cursor-pointer items-center p-4",
          itemClassName,
          itemHoverClassName,
          itemActiveClassName,
          active && itemFocusClassName,
          className
        )}
        href={href}
        onClick={onClick}
        type={Component === "button" ? "button" : undefined}
      >
        {iconStart &&
          cloneElement(iconStart, {
            className: clsx("mr-4 shrink-0", iconStart.props.className),
          })}
        {children}
        {iconEnd &&
          cloneElement(iconEnd, {
            className: clsx("ml-auto shrink-0", iconEnd.props.className),
          })}
      </Component>
    );
  }
);

export type GlobalNavDropdownMenuItemProps = GlobalNavDropdownItemProps;

export function GlobalNavDropdownMenuItem({
  as = "a",
  children,
  className,
  href,
  iconStart,
  iconEnd,
  onClick,
}: GlobalNavDropdownMenuItemProps) {
  return (
    <Menu.Item key={href}>
      {({ active }) => (
        <GlobalNavDropdownItem
          active={active}
          as={as}
          className={className}
          href={href}
          iconStart={iconStart}
          iconEnd={iconEnd}
          onClick={onClick}
        >
          {children}
        </GlobalNavDropdownItem>
      )}
    </Menu.Item>
  );
}
