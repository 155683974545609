import { Menu } from "@headlessui/react";
import { clsx } from "clsx";
import React, { MouseEvent, useState } from "react";
import AIDropdownIcon from "../assets/dropdown/icon-ai.svg";
import ChevronIcon from "../assets/dropdown/icon-chevron-down.svg";
import HCPDropdownIcon from "../assets/dropdown/icon-hcp.svg";
import PinnacleMainDropdownIcon from "../assets/dropdown/icon-pinnacle-qi.svg";
import PinnacleRetainDropdownIcon from "../assets/dropdown/icon-pinnacle-retain.svg";
import HCPExperienceIcon from "../assets/global-nav/icon-hcp-experience.svg";
import HCPReputationIcon from "../assets/global-nav/icon-hcp-reputation.svg";
import HCPTrainingIcon from "../assets/global-nav/icon-hcp-training.svg";
import MoreIcon from "../assets/icon-more.svg";
import {
  GlobalNavDropdown,
  GlobalNavDropdownItem,
  GlobalNavDropdownMenuItem,
  GlobalNavDropdownMenuItems,
} from "../components/common/Dropdown";
import { GlobalNavTab } from "../components/common/Tab";
import { TransitionHeight } from "../components/common/TransitionHeight";
import { GlobalNavVariation } from "../constants/constants";
import { makeUrl } from "../constants/strings";
import {
  ACTIVATED_INSIGHTS_PATH,
  HCP_EXPERIENCE_PATH,
  HCP_REPUTATION_PATH,
  HCP_TRAINING_PATH,
  PINNACLE_CAHPS_PATH,
  PINNACLE_QI_PATH,
  PINNACLE_RETAIN_PATH,
} from "../constants/urls";
import { useCrossModalContext } from "../hooks/useCrossModalContext";
import { useGlobalNavContext } from "../hooks/useGlobalNavContext";
import {
  GlobalNavPermissions,
  usePermissionsContext,
} from "../hooks/usePermissionsContext";

const experienceItems: GlobalNavDropdownItem[] = [
  {
    href: HCP_EXPERIENCE_PATH,
    hostKey: "hcpExperienceAppHost",
    icon: <HCPDropdownIcon />,
    title: "HCP Experience Management",
    permissionsKey: "canAccessHcpExperience",
    modalVariation: GlobalNavVariation.HCP_EXPERIENCE,
  },
  {
    href: ACTIVATED_INSIGHTS_PATH,
    hostKey: "activatedInsightsAppHost",
    icon: <AIDropdownIcon />,
    title: "Activated Insights",
    permissionsKey: "canAccessActivatedInsights",
    modalVariation: GlobalNavVariation.ACTIVATED_INSIGHTS,
  },
  {
    href: PINNACLE_RETAIN_PATH,
    hostKey: "pinnacleRetainAppHost",
    icon: <PinnacleRetainDropdownIcon />,
    title: "Retain by Pinnacle",
    permissionsKey: "canAccessPinnacleRetain",
    modalVariation: GlobalNavVariation.PINNACLE_RETAIN,
  },
  {
    href: PINNACLE_QI_PATH,
    hostKey: "pinnacleQiAppHost",
    icon: <PinnacleMainDropdownIcon />,
    title: "Customer Satisfaction by Pinnacle",
    permissionsKey: "canAccessPinnacleMain",
    modalVariation: GlobalNavVariation.PINNACLE_QI,
  },
  {
    href: PINNACLE_CAHPS_PATH,
    hostKey: "pinnacleCahpsAppHost",
    icon: <PinnacleMainDropdownIcon />,
    title: "CAHPS by Pinnacle",
    permissionsKey: "canAccessPinnacleCAHPS",
    modalVariation: GlobalNavVariation.PINNACLE_CAHPS,
  },
];

export function GlobalNavTabs() {
  const [isExperienceOpen, setIsExperienceOpen] = useState(false);
  const { hosts } = useGlobalNavContext();
  const { permissions } = usePermissionsContext();
  const { setIsCrossModalOpen, setCrossModalVariation } =
    useCrossModalContext();

  const makeOnClickHandler =
    ({
      permissionsKey,
      modalVariation,
    }: {
      permissionsKey: keyof GlobalNavPermissions;
      modalVariation: GlobalNavVariation;
    }) =>
    (e: MouseEvent<HTMLElement>) => {
      if (!permissions[permissionsKey]) {
        e.preventDefault();
        setCrossModalVariation(modalVariation);
        setIsCrossModalOpen(true);
        return;
      }
    };

  return (
    <>
      <div className="hidden h-full lg:flex">
        <GlobalNavDropdown>
          <Menu>
            {({ open }) => (
              <>
                <Menu.Button
                  as={GlobalNavTab}
                  component="span"
                  href={makeUrl(
                    hosts.hcpExperienceAppHost,
                    HCP_EXPERIENCE_PATH
                  )}
                  iconStart={<HCPExperienceIcon />}
                  className={clsx(open && "bg-white/25")}
                >
                  Experience
                  <ChevronIcon className="ml-2" />
                </Menu.Button>
                <GlobalNavDropdownMenuItems className="w-80">
                  {experienceItems.map((item) => (
                    <GlobalNavDropdownMenuItem
                      key={item.href}
                      href={makeUrl(hosts[item.hostKey], item.href)}
                      iconStart={item.icon}
                      onClick={makeOnClickHandler(item)}
                    >
                      {item.title}
                    </GlobalNavDropdownMenuItem>
                  ))}
                </GlobalNavDropdownMenuItems>
              </>
            )}
          </Menu>
        </GlobalNavDropdown>
        <GlobalNavTab
          href={makeUrl(hosts.hcpTrainingAppHost, HCP_TRAINING_PATH)}
          iconStart={<HCPTrainingIcon />}
          onClick={makeOnClickHandler({
            permissionsKey: "canAccessHcpTraining",
            modalVariation: GlobalNavVariation.HCP_TRAINING,
          })}
        >
          Training
        </GlobalNavTab>
        <GlobalNavTab
          href={makeUrl(hosts.hcpReputationAppHost, HCP_REPUTATION_PATH)}
          iconStart={<HCPReputationIcon />}
          onClick={makeOnClickHandler({
            permissionsKey: "canAccessHcpReputation",
            modalVariation: GlobalNavVariation.HCP_REPUTATION,
          })}
        >
          Reputation
        </GlobalNavTab>
      </div>
      <div className="h-full lg:hidden">
        <Menu>
          {({ open }) => (
            <>
              <Menu.Button
                as={GlobalNavTab}
                component="span"
                href={makeUrl(hosts.hcpExperienceAppHost, HCP_EXPERIENCE_PATH)}
                className={clsx(open && "bg-white/25")}
              >
                <MoreIcon />
              </Menu.Button>
              <GlobalNavDropdownMenuItems className="inset-x-4">
                <GlobalNavDropdownItem
                  as="button"
                  iconStart={<HCPExperienceIcon />}
                  iconEnd={
                    <ChevronIcon
                      className={clsx({ "rotate-180": isExperienceOpen })}
                    />
                  }
                  onClick={(e) => {
                    // stop menu from closing
                    e.stopPropagation();
                    setIsExperienceOpen((isOpen) => !isOpen);
                  }}
                >
                  Experience
                </GlobalNavDropdownItem>
                <TransitionHeight show={isExperienceOpen}>
                  <div className="bg-gray-100">
                    {experienceItems.map((item) => (
                      <GlobalNavDropdownMenuItem
                        href={makeUrl(hosts[item.hostKey], item.href)}
                        onClick={makeOnClickHandler(item)}
                      >
                        {item.title}
                      </GlobalNavDropdownMenuItem>
                    ))}
                  </div>
                </TransitionHeight>
                <GlobalNavDropdownMenuItem
                  href={makeUrl(
                    hosts.hcpReputationAppHost,
                    HCP_REPUTATION_PATH
                  )}
                  iconStart={<HCPReputationIcon />}
                  onClick={makeOnClickHandler({
                    permissionsKey: "canAccessHcpReputation",
                    modalVariation: GlobalNavVariation.HCP_REPUTATION,
                  })}
                >
                  Reputation
                </GlobalNavDropdownMenuItem>
                <GlobalNavDropdownMenuItem
                  href={makeUrl(hosts.hcpTrainingAppHost, HCP_TRAINING_PATH)}
                  iconStart={<HCPTrainingIcon />}
                  onClick={makeOnClickHandler({
                    permissionsKey: "canAccessHcpTraining",
                    modalVariation: GlobalNavVariation.HCP_TRAINING,
                  })}
                >
                  Training
                </GlobalNavDropdownMenuItem>
              </GlobalNavDropdownMenuItems>
            </>
          )}
        </Menu>
      </div>
    </>
  );
}
