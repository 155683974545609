import register from "preact-custom-element";
import React from "react";
import { GlobalNav, GlobalNavProps } from "./components/GlobalNav";
import cssPath from "./css/global-nav.css";

function GlobalNavWebComponent(props: GlobalNavProps) {
  return (
    <>
      <link rel="stylesheet" href={cssPath} />
      <GlobalNav {...props} />
    </>
  );
}

register(
  GlobalNavWebComponent,
  "hcp-global-nav",
  [
    "can-access-activated-insights",
    "can-access-hcp-experience",
    "can-access-hcp-reputation",
    "can-access-hcp-training",
    "can-access-pinnacle-cahps",
    "can-access-pinnacle-qi",
    "can-access-pinnacle-retain",
    "class-name",
    "company-name",
    "edit-profile-url",
    "file-upload-url",
    "user-name",
    "variation",
  ],
  { shadow: true }
);
