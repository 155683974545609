export const CLICK_LOGO_EVENT_NAME = "globalNavClickLogo";
export const CLICK_LOGOUT_EVENT_NAME = "globalNavClickLogout";

export enum GlobalNavSite {
  AI = "AI",
  HCP = "HCP",
  PIN = "PIN",
}

export enum GlobalNavVariation {
  ACTIVATED_INSIGHTS = "ACTIVATED_INSIGHTS",
  HCP_EXPERIENCE = "HCP_EXPERIENCE",
  HCP_REPUTATION = "HCP_REPUTATION",
  HCP_TRAINING = "HCP_TRAINING",
  PINNACLE_CAHPS = "PINNACLE_CAHPS",
  PINNACLE_QI = "PINNACLE_QI",
  PINNACLE_RETAIN = "PINNACLE_RETAIN",
}

export const variationToSiteMap: Record<GlobalNavVariation, GlobalNavSite> = {
  [GlobalNavVariation.ACTIVATED_INSIGHTS]: GlobalNavSite.AI,
  [GlobalNavVariation.HCP_EXPERIENCE]: GlobalNavSite.HCP,
  [GlobalNavVariation.HCP_REPUTATION]: GlobalNavSite.HCP,
  [GlobalNavVariation.HCP_TRAINING]: GlobalNavSite.HCP,
  [GlobalNavVariation.PINNACLE_CAHPS]: GlobalNavSite.PIN,
  [GlobalNavVariation.PINNACLE_QI]: GlobalNavSite.PIN,
  [GlobalNavVariation.PINNACLE_RETAIN]: GlobalNavSite.PIN,
};
