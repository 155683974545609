import { createContext, useContext } from "react";
import { GlobalNavVariation } from "../constants/constants";

export interface CrossModalContextState {
  crossModalVariation: GlobalNavVariation | null;
  setCrossModalVariation: (
    crossModalVariation: GlobalNavVariation | null
  ) => void;
  isCrossModalOpen: boolean;
  setIsCrossModalOpen: (isCrossModalOpen: boolean) => void;
}

export const CrossModalContext = createContext<CrossModalContextState>({
  crossModalVariation: null,
  setCrossModalVariation: () => {
    // ain't nobody here but us chickens
  },
  isCrossModalOpen: false,
  setIsCrossModalOpen: () => {
    // ain't nobody here but us chickens
  },
});

export function useCrossModalContext() {
  return useContext(CrossModalContext);
}
