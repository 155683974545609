import { Transition } from "@headlessui/react";
import React, { ReactNode, useRef } from "react";

interface TransitionHeightProps {
  children: ReactNode;
  show: boolean;
}

export function TransitionHeight({ children, show }: TransitionHeightProps) {
  const outerRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);

  const captureHeight = () => {
    if (!outerRef.current || !innerRef.current) return;
    outerRef.current.style.height = `${innerRef.current.clientHeight}px`;
  };
  const clearHeight = () => {
    if (!outerRef.current) return;
    outerRef.current.style.height = "";
  };

  return (
    <Transition
      ref={outerRef}
      show={show}
      beforeEnter={captureHeight}
      afterEnter={clearHeight}
      enter="transition-[height] duration-300 overflow-hidden"
      enterFrom="!h-0"
      beforeLeave={captureHeight}
      afterLeave={clearHeight}
      leave="transition-[height] duration-300 overflow-hidden"
      leaveTo="!h-0"
    >
      <div ref={innerRef}>{children}</div>
    </Transition>
  );
}
