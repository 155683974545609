import React from "react";
import { invariant } from "ts-invariant";
import ActivatedInsightsLogo from "../../assets/modal/logo-ai.svg";
import HCPExerienceLogo from "../../assets/modal/logo-hcp-experience.svg";
import HCPReputationLogo from "../../assets/modal/logo-hcp-reputation.svg";
import HCPTrainingLogo from "../../assets/modal/logo-hcp-training.svg";
import PinnacleQILogo from "../../assets/modal/logo-pinnacle-qi.svg";
import PinnacleRetainLogo from "../../assets/modal/logo-pinnacle-retain.svg";
import { GlobalNavVariation } from "../../constants/constants";
import { useCrossModalContext } from "../../hooks/useCrossModalContext";

const getVariationLogo = (variation: GlobalNavVariation) => {
  switch (variation) {
    case GlobalNavVariation.ACTIVATED_INSIGHTS:
      return ActivatedInsightsLogo;
    case GlobalNavVariation.HCP_EXPERIENCE:
      return HCPExerienceLogo;
    case GlobalNavVariation.HCP_REPUTATION:
      return HCPReputationLogo;
    case GlobalNavVariation.HCP_TRAINING:
      return HCPTrainingLogo;
    case GlobalNavVariation.PINNACLE_RETAIN:
      return PinnacleRetainLogo;
    case GlobalNavVariation.PINNACLE_QI:
      return PinnacleQILogo;
    case GlobalNavVariation.PINNACLE_CAHPS:
      return PinnacleQILogo;
    default:
      throw new Error("Invalid variation");
  }
};

export function CrossModalLogo() {
  const { crossModalVariation } = useCrossModalContext();

  invariant(crossModalVariation);

  const LogoComponent = getVariationLogo(crossModalVariation);

  return <LogoComponent className="mx-auto " />;
}
