import React from "react";
import { invariant } from "ts-invariant";
import { AnchorButton } from "../../components/common/Button";
import { getVariationLabel } from "../../constants/strings";
import { DEMO_URL } from "../../constants/urls";
import { useCrossModalContext } from "../../hooks/useCrossModalContext";

export function CrossModalDemo() {
  const { crossModalVariation } = useCrossModalContext();

  invariant(crossModalVariation);

  return (
    <div className="hcp:bg-hcp-gray-200 bg-gray-100 px-8 py-6 text-center">
      <h3 className="font-bold">Want to gain access?</h3>
      <p className="mb-4 text-gray-700">
        Meet with a product expert to learn more about{" "}
        {getVariationLabel(crossModalVariation)}
      </p>
      <AnchorButton href={DEMO_URL} target="_blank">
        Get a Live Demo
      </AnchorButton>
    </div>
  );
}
