import { useGlobalNavContext } from "../hooks/useGlobalNavContext";
import { GlobalNavVariation } from "./constants";
import {
  ACTIVATED_INSIGHTS_LOGIN_PATH,
  ACTIVATED_INSIGHTS_LOGOUT_PATH,
  HCP_EXPERIENCE_LOGIN_PATH,
  HCP_EXPERIENCE_LOGOUT_PATH,
  HCP_REPUTATION_LOGIN_PATH,
  HCP_REPUTATION_LOGOUT_PATH,
  HCP_TRAINING_LOGIN_PATH,
  HCP_TRAINING_LOGOUT_PATH,
  PINNACLE_CAHPS_LOGIN_PATH,
  PINNACLE_CAHPS_LOGOUT_PATH,
  PINNACLE_QI_LOGIN_PATH,
  PINNACLE_QI_LOGOUT_PATH,
  PINNACLE_RETAIN_LOGIN_PATH,
  PINNACLE_RETAIN_LOGOUT_PATH,
} from "./urls";

export function makeUrl(host: string, path: string) {
  return `${host}${path}`;
}

export const getVariationLabel = (variation: GlobalNavVariation) => {
  switch (variation) {
    case GlobalNavVariation.ACTIVATED_INSIGHTS:
      return "Activated Insights";
    case GlobalNavVariation.HCP_EXPERIENCE:
      return "HCP Experience Management";
    case GlobalNavVariation.HCP_REPUTATION:
      return "HCP Reputation Management";
    case GlobalNavVariation.HCP_TRAINING:
      return "HCP Training";
    case GlobalNavVariation.PINNACLE_RETAIN:
      return "Retain by Pinnacle";
    case GlobalNavVariation.PINNACLE_QI:
      return "Customer Satisfaction by Pinnacle";
    case GlobalNavVariation.PINNACLE_CAHPS:
      return "CAHPS by Pinnacle";
    default:
      throw new Error("Invalid variation");
  }
};

export const useVariationLoginUrl = (variation: GlobalNavVariation) => {
  const { hosts } = useGlobalNavContext();
  switch (variation) {
    case GlobalNavVariation.ACTIVATED_INSIGHTS:
      return makeUrl(
        hosts.activatedInsightsAppHost,
        ACTIVATED_INSIGHTS_LOGIN_PATH
      );
    case GlobalNavVariation.HCP_EXPERIENCE:
      return makeUrl(hosts.hcpExperienceAppHost, HCP_EXPERIENCE_LOGIN_PATH);
    case GlobalNavVariation.HCP_REPUTATION:
      return makeUrl(hosts.hcpReputationAppHost, HCP_REPUTATION_LOGIN_PATH);
    case GlobalNavVariation.HCP_TRAINING:
      return makeUrl(hosts.hcpTrainingAppHost, HCP_TRAINING_LOGIN_PATH);
    case GlobalNavVariation.PINNACLE_RETAIN:
      return makeUrl(hosts.pinnacleRetainAppHost, PINNACLE_RETAIN_LOGIN_PATH);
    case GlobalNavVariation.PINNACLE_QI:
      return makeUrl(hosts.pinnacleQiAppHost, PINNACLE_QI_LOGIN_PATH);
    case GlobalNavVariation.PINNACLE_CAHPS:
      return makeUrl(hosts.pinnacleCahpsAppHost, PINNACLE_CAHPS_LOGIN_PATH);
    default:
      throw new Error("Invalid variation");
  }
};

export const useVariationLogoutUrl = (variation: GlobalNavVariation) => {
  const { hosts } = useGlobalNavContext();
  switch (variation) {
    case GlobalNavVariation.ACTIVATED_INSIGHTS:
      return ACTIVATED_INSIGHTS_LOGOUT_PATH;
    case GlobalNavVariation.HCP_EXPERIENCE:
      return makeUrl(hosts.hcpExperienceAppHost, HCP_EXPERIENCE_LOGOUT_PATH);
    case GlobalNavVariation.HCP_REPUTATION:
      return makeUrl(hosts.hcpReputationAppHost, HCP_REPUTATION_LOGOUT_PATH);
    case GlobalNavVariation.HCP_TRAINING:
      return makeUrl(hosts.hcpTrainingAppHost, HCP_TRAINING_LOGOUT_PATH);
    case GlobalNavVariation.PINNACLE_RETAIN:
      return makeUrl(hosts.pinnacleRetainAppHost, PINNACLE_RETAIN_LOGOUT_PATH);
    case GlobalNavVariation.PINNACLE_QI:
      return makeUrl(hosts.pinnacleQiAppHost, PINNACLE_QI_LOGOUT_PATH);
    case GlobalNavVariation.PINNACLE_CAHPS:
      return makeUrl(hosts.pinnacleCahpsAppHost, PINNACLE_CAHPS_LOGOUT_PATH);
    default:
      throw new Error("Invalid variation");
  }
};
