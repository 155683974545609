import { clsx } from "clsx";
import React from "react";
import { invariant } from "ts-invariant";
import { GlobalNavVariation } from "../../constants/constants";
import { useCrossModalContext } from "../../hooks/useCrossModalContext";

export const getVariationSubtitle = (variation: GlobalNavVariation) => {
  switch (variation) {
    case GlobalNavVariation.ACTIVATED_INSIGHTS:
      return "Industry Certifications & Online Satisfaction Surveys for Senior Care";
    case GlobalNavVariation.HCP_EXPERIENCE:
      return "Over-the-phone satisfaction surveys for home-based care";
    case GlobalNavVariation.HCP_REPUTATION:
      return "Online review manager for senior care businesses";
    case GlobalNavVariation.HCP_TRAINING:
      return "Training Management for Senior Care";
    case GlobalNavVariation.PINNACLE_RETAIN:
      return "Employee Retention Management for Senior Care";
    case GlobalNavVariation.PINNACLE_QI:
      return "Over-the-phone satisfaction surveys for senior care";
    case GlobalNavVariation.PINNACLE_CAHPS:
      return "Over-the-phone CAHPS surveys for home health and hospice";
    default:
      throw new Error("Invalid variation");
  }
};

interface CrossModalSubtitleProps {
  className?: string;
}

export function CrossModalSubtitle({ className }: CrossModalSubtitleProps) {
  const { crossModalVariation } = useCrossModalContext();

  invariant(crossModalVariation);

  return (
    <p className={clsx("text-center", className)}>
      {getVariationSubtitle(crossModalVariation)}
    </p>
  );
}
